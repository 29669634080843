export const circleBurner = [
    {
        "inputs":[
            {
                "internalType":"address",
                "name":"messenger_",
                "type":"address"
            },
            {
                "internalType":"address",
                "name":"feeOracle_",
                "type":"address"
            }
        ],
        "stateMutability":"nonpayable",
        "type":"constructor"
    },
    {
        "inputs":[
            {
                "internalType":"address",
                "name":"target",
                "type":"address"
            }
        ],
        "name":"AddressEmptyCode",
        "type":"error"
    },
    {
        "inputs":[
            {
                "internalType":"address",
                "name":"account",
                "type":"address"
            }
        ],
        "name":"AddressInsufficientBalance",
        "type":"error"
    },
    {
        "inputs":[
            
        ],
        "name":"FailedInnerCall",
        "type":"error"
    },
    {
        "inputs":[
            {
                "internalType":"address",
                "name":"token",
                "type":"address"
            }
        ],
        "name":"SafeERC20FailedOperation",
        "type":"error"
    },
    {
        "stateMutability":"payable",
        "type":"fallback"
    },
    {
        "inputs":[
            {
                "internalType":"uint256",
                "name":"amount",
                "type":"uint256"
            },
            {
                "internalType":"uint32",
                "name":"destinationDomain",
                "type":"uint32"
            },
            {
                "internalType":"bytes32",
                "name":"mintRecipient",
                "type":"bytes32"
            },
            {
                "internalType":"string",
                "name":"symbol",
                "type":"string"
            }
        ],
        "name":"depositForBurn",
        "outputs":[
            
        ],
        "stateMutability":"payable",
        "type":"function"
    },
    {
        "inputs":[
            
        ],
        "name":"feeOracle",
        "outputs":[
            {
                "internalType":"address",
                "name":"",
                "type":"address"
            }
        ],
        "stateMutability":"view",
        "type":"function"
    },
    {
        "inputs":[
            
        ],
        "name":"messenger",
        "outputs":[
            {
                "internalType":"address",
                "name":"",
                "type":"address"
            }
        ],
        "stateMutability":"view",
        "type":"function"
    },
    {
        "inputs":[
            {
                "internalType":"string",
                "name":"",
                "type":"string"
            }
        ],
        "name":"tokenFee",
        "outputs":[
            {
                "internalType":"uint256",
                "name":"",
                "type":"uint256"
            }
        ],
        "stateMutability":"view",
        "type":"function"
    },
    {
        "inputs":[
            {
                "internalType":"string",
                "name":"",
                "type":"string"
            }
        ],
        "name":"tokens",
        "outputs":[
            {
                "internalType":"address",
                "name":"",
                "type":"address"
            }
        ],
        "stateMutability":"view",
        "type":"function"
    },
    {
        "inputs":[
            {
                "internalType":"address",
                "name":"newAdmin",
                "type":"address"
            }
        ],
        "name":"updateAdmin",
        "outputs":[
            
        ],
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "inputs":[
            {
                "internalType":"address",
                "name":"feeOracle_",
                "type":"address"
            }
        ],
        "name":"updateFeeOracle",
        "outputs":[
            
        ],
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "inputs":[
            {
                "internalType":"address",
                "name":"newMessenger",
                "type":"address"
            }
        ],
        "name":"updateMessenger",
        "outputs":[
            
        ],
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "inputs":[
            {
                "internalType":"string",
                "name":"symbol",
                "type":"string"
            },
            {
                "internalType":"address",
                "name":"tokenContract",
                "type":"address"
            }
        ],
        "name":"updateToken",
        "outputs":[
            
        ],
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "inputs":[
            
        ],
        "name":"withdraw",
        "outputs":[
            
        ],
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "inputs":[
            {
                "internalType":"string",
                "name":"symbol",
                "type":"string"
            }
        ],
        "name":"withdrawTokenFee",
        "outputs":[
            
        ],
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "stateMutability":"payable",
        "type":"receive"
    }
] as const;