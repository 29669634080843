import react from 'react';
import './ButtonSpinner.css';

export default function ButtonSpinner() {
    return (
        <div className="lds-ring">
            <div>

            </div>
            <div>

            </div>
            <div>

            </div>
            <div>

            </div>
        </div>
    );
}